<template>
    <div class="card card-news" :class="[isCurrentlyActiveCard, 'card', desktopAuto ? 'card-desktop' : 'card-mobile']" :title="linkToTitle">
        <a :href="institutionUrl" @click="navigateToView">
            <span class="alignment"></span>
            <div class="card-image" :style="getStyle" v-if="showDetails">
            </div>
            <div class="card-body">
                <bookmark-button :parentClass="'card-body-icon'" :content="content" v-if="content!==null && showDetails" />
                <h5>{{ title }}</h5>
                <h6 class="info-text" v-if="readAbleCategories!=null && showDetails">{{ readAbleCategories }}</h6>
            </div>
        </a>
    </div>
</template>
<script>
    import { bus } from '@/main'
    import { screenSizeMixin } from '@/mixins/screenSizeMixin';
    import { getFieldValues, json_decode } from '@/utils/helpers';
    import { getCategoryPlaceholderImage } from '@/utils/tripmeister-helpers';
    import { getNextAppointment, isOneOfTheAppointmentsNow, getWeekday, formatTime, getMonth } from '@/utils/appointment-helpers';

    export default {
        name: 'LocationCard',
        mixins: [screenSizeMixin],
        components: {
            BookmarkButton: () => import('@/components/controls/BookmarkButton'),
        },
        props: {
            item: {
                type: Object,
                required: true
            },
            desktopAuto: {
                type: Boolean,
            default: false
            },
            hideFullContent: {
                type: Boolean,
            default: false
            },
            icon: {
                type: String,
            default: 'map',
                validator: icon => ['map', 'edit'].indexOf(icon) !== -1
            },
            disableLink: {
                type: Boolean,
            default: false
            }
        },
        computed: {
            institutionUrl() {
                let props = this.$router.resolve({
                    name: 'LocationView',
                    params: { id: this.item.id },
                });

                return props.href;
            },
            content() {
                if (this.item != null) {
                    return this.item;
                }
                return null;
            },
            showDetails() {
                if (this.$isMobileScreen && this.hideFullContent) {
                    if (this.item.highlight) {
                        return true;
                    }
                    return false;
                }
                return true;
            },
            title() {
                if (this.item != null) {
                    var title = this.content.title;
                    if (this.isSmall) {
                        return title.length > 26 ? title.substring(0, 26) + ' ...' : title;
                    } else {
                        return title.length > 30 ? title.substring(0, 30) + ' ...' : title;
                    }
                }
                return "";
            },
            linkToTitle() {
                return "Mehr Informationen zu: " + this.item.title;
            },
            categories() {
                return getFieldValues(this.item, 'kategorien');
            },
            isCurrentlyActiveCard() {
                if (this.content.highlight == true) {
                    return "highlight";
                }
                return 'not-highlighted';
            },
            category() {
                if (this.categories != null && Array.isArray(this.categories) && this.categories.length > 0) {
                    return this.categories[0];
                } else if (this.categories != null && !Array.isArray(this.categories)) {
                    return this.categories;
                }
                return null
            },
            readAbleCategories() {
                if (this.categories != null && Array.isArray(this.categories) && this.categories.length > 0) {
                    let shortenedCategories = this.categories.slice(0, 2);
                    if (this.categories.length > 2) {
                        return shortenedCategories.join(", ") + "...";
                    }
                    return shortenedCategories.join(", ");
                } else if (this.categories != null && !Array.isArray(this.categories)) {
                    return this.categories;
                }
                return null
            },
            teaserImages() {
                var pictures = getFieldValues(this.item, 'teaser-bild');
                if (pictures != null) {
                    if (Array.isArray(pictures)) {
                        return pictures;
                    } else {
                        return [pictures];
                    }
                }
                return null;
            },
            teaserImage() {
                var images = this.teaserImages;
                if (images != null) {
                    var image = images[Math.floor(Math.random() * images.length)];
                    image = json_decode(image);
                    if (typeof image === "object") {
                        if (image.path.indexOf('https') !== -1) {
                            return image.path;
                        } else {
                            return this.$backendUrl + image.path;
                        }
                    } else {
                        return this.$backendUrl + image;
                    }
                }

            /*we'll need an alternative if there is no image*/
                return this.getCategoryPlaceholderImage(this.category);
            },
            getStyle() {
                return 'backgroundImage: url("' + this.teaserImage + '")';
            }
        },
        methods: {
            getFieldValues,
            getCategoryPlaceholderImage,
            json_decode,
            navigateToView(event) {
                if (this.disableLink) {
                    this.$emit('highlight', { sender: 'institution-card', content: this.item });
                    event.preventDefault()
                }
            },
            showReiseplanModal() {
                bus.$emit('reiseplan-modal', true)
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

    .card {
        position: relative;
        border: none;
        box-shadow: 2px 2px 5px rgba($color: $dark-color, $alpha: 0.35);
        border-radius: 15px;
        margin-right: 10px;
        margin-bottom: 10px;
        min-width: 198px;
        min-height: 90px;
        width: 198px;

        a {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &-desktop {
            width: 100%;
            min-width: 300px;

            @include responsive($scroll-breakpoint) {
                width: 200px;
            }
        }

        &-mobile {
            border: none !important;
        }

        &.highlight {
            border: 3px solid $primary;
            border-radius: 18px;
        }


        &-image {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            max-width: 100%;
            min-height: 150px;
            max-height: 150px;
            overflow: hidden;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;

            @media(max-width: 991px) {
                min-height: 100px;
                max-height: 100px;
            }

            img {
                min-width: 100%;
                height: 150px;
                width: auto;
            }
        }

        &-body {
            position: relative;
            padding: 1rem 0.5rem;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;

            &-icon {
                position: absolute;
                background: rgba($color: $white-color, $alpha: 1.0);
                box-shadow: 2px 2px 5px rgba($color: $dark-color, $alpha: 0.35);
                border-radius: 50%;
                height: 50px;
                width: 50px;
                right: 8px;
                top: -25px;
                padding: 5px;

                .icon {
                    text-align: center;
                    border: 1px solid $dark-color;
                    border-radius: 50px;
                    height: 40px;
                    width: 40px;
                    display: inline-block;
                    line-height: 36px;
                    text-align: center;
                    font-size: 24px;
                    color: $dark-color;

                    &.edit {
                        border-color: transparent;
                        color: $text-color;
                    }
                }
            }

            h5 {
                font-size: 14px;
                font-weight: 700;
                margin-right: 50px;
                margin-bottom: 5px;
            }

            .time-text {
                font-size: 12px;
                font-weight: 400;
                color: $dark-color;
                margin-bottom: 10px;
            }

            .info-text {
                margin-top: auto;
                font-size: 12px;
                text-transform: uppercase;
                color: $primary;
            }
        }
    }

    .first-elem {
        margin-left: 10px;
    }
</style>